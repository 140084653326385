<template>
  <el-dialog
    title="代码库管理"
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <el-radio-group v-model="activeName" size="small" class="mb-20">
        <el-radio-button label="draft">代码草稿箱</el-radio-button>
        <el-radio-button label="template">代码模板库</el-radio-button>
      </el-radio-group>
      <el-table v-if="activeName == 'draft'" :data="draftList" style="width: 100%" border header-row-class-name="table-header">
        <el-table-column align="center" prop="draftId" label="草稿ID" width="80"></el-table-column>
        <el-table-column align="center" prop="userVersion" label="版本号" width="120"></el-table-column>
        <el-table-column prop="userDesc" label="版本描述"></el-table-column>
        <el-table-column align="center" prop="timeFmt" label="上传草稿时间" width="160"></el-table-column>
        <el-table-column align="center" label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" @click="addToTemplate(scope.row)">添加到模板库</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else :data="templateList" style="width: 100%" border header-row-class-name="table-header">
        <el-table-column align="center" prop="templateId" label="模板ID" width="80"></el-table-column>
        <el-table-column align="center" prop="userVersion" label="版本号" width="120"></el-table-column>
        <el-table-column prop="userDesc" label="版本描述"></el-table-column>
        <el-table-column align="center" prop="timeFmt" label="被添加为模板时间" width="160"></el-table-column>
        <el-table-column align="center" label="操作" width="120">
          <template slot-scope="scope">
            <el-popconfirm
              title="是否确认删除该模板？"
              @confirm="deleteTemplate(scope.row)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { get_draft_list, get_template_list, add_to_template, delete_template } from '@/api/miniprogram'
import moment from 'moment'
export default {
  props: {
    visible: {
      type: Boolean
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      draftList: [],
      templateList: [],
      activeName: 'draft'
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getList()
        this.getTemplateList()
      }
    },
    activeName(val) {
      if (val == 'draft') {
        this.getList()
      } else {
        this.getTemplateList()
      }
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:visible', false)
    },
    getList() {
      get_draft_list().then(res => {
        this.draftList = (res || []).map(o => {
          return {
            ...o,
            timeFmt: moment.unix(o.createTime).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        this.draftList.sort((a, b) => {
          return b.createTime - a.createTime
        })
      })
    },
    getTemplateList() {
      get_template_list({
        templateType: 0,
        sourceMiniprogramAppid: this.type == 'normal'?'wx155132dc5d7a83b2':'wxaae7fbbc14c441c8'
      }).then(res => {
        this.templateList = (res || []).map(o => {
          return {
            ...o,
            timeFmt: moment.unix(o.createTime).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        this.templateList.sort((a, b) => {
          return b.createTime - a.createTime
        })
      })
    },
    addToTemplate(item) {
      add_to_template({
        draftId: item.draftId,
        templateType: 0
      }).then(() => {
        this.$message.success('添加成功')
      })
    },
    deleteTemplate(item) {
      delete_template({
        templateId: item.templateId
      }).then(() => {
        this.$message.success('删除成功')
        this.getTemplateList()
      })
    }
  }
}
</script>

<style>

</style>